import andre from "../assets/png/friends/andre.jpg";
import maisa from "../assets/png/friends/maisa.jpg";
import tonetti from "../assets/png/friends/tonetti.jpg";
import aline47 from "../assets/png/friends/aline47.jpg";
import egb48 from "../assets/png/friends/egb48.jpg";
import igor51 from "../assets/png/friends/igor51.jpg";
import frodo54 from "../assets/png/friends/frodo54.jpg";
import branda43 from "../assets/png/friends/branda43.jpg";
import kadu53 from "../assets/png/friends/kadu53.jpg";
import luiz55 from "../assets/png/friends/luiz55.jpg";
import pri46 from "../assets/png/friends/pri46.jpg";
import tuji50 from "../assets/png/friends/tuji50.jpg";
import thiago56 from "../assets/png/friends/thiago56.jpg";
import lari57 from "../assets/png/friends/lari57.jpg";
import julyandro60 from "../assets/png/friends/julyandro60.jpg";
import mathias61 from "../assets/png/friends/mathias61.jpg";
import lucas62 from "../assets/png/friends/lucas62.jpg";
import serafas63 from "../assets/png/friends/serafas.jpg";
import lucas64 from "../assets/png/friends/lucas64.jpg";

export const referencesPhotos = [
  {
    idPessoa45: andre,
    idPessoa49: maisa,
    idPessoa52: tonetti,
    idPessoa47: aline47,
    idPessoa48: egb48,
    idPessoa51: igor51,
    idPessoa54: frodo54,
    idPessoa43: branda43,
    idPessoa53: kadu53,
    idPessoa55: luiz55,
    idPessoa46: pri46,
    idPessoa50: tuji50,
    idPessoa56: thiago56,
    idPessoa57: lari57,
    idPessoa60: julyandro60,
    idPessoa61: mathias61,
    idPessoa62: lucas62,
    idPessoa63: serafas63,
    idPessoa64: lucas64
  },
];
