import React, { useContext, useState, useEffect } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { IoMenuSharp, IoHomeSharp } from "react-icons/io5";
import { MdPhone, MdOutlineLaptopMac } from "react-icons/md";
import { FaUser, FaFolderOpen, FaReact, FaUserGraduate } from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import { AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaBook } from "react-icons/fa6";
import { ThemeContext } from "../../contexts/ThemeContext.js";
import { Snackbar, IconButton, SnackbarContent } from "@material-ui/core";
import { VscFeedback } from "react-icons/vsc";
import "./Navbar.css";

function Navbar() {
  const { theme, setHandleDrawer, switchTheme } = useContext(ThemeContext);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [open, setOpen] = useState(false);
  const [colorTheme, setColorTheme] = useState("blue");
  const [success, setSuccess] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("futsal")) {
      switchTheme(false, "blue");
      setIsDarkMode(false);
    }

    const handleScroll = () => {
      let scrollSizeY = 50;
      if (window.location.pathname === "/" && window.innerWidth > 600) {
        scrollSizeY = 770;
      }
      if (window.scrollY > scrollSizeY) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCheckboxChange = (color) => {
    setColorTheme(color);
    switchTheme(isDarkMode, color);
  };

  const handleToggle = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    switchTheme(newMode, colorTheme);
    setSuccess(true);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setHandleDrawer();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setHandleDrawer();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  const useStyles = makeStyles((t) => ({
    message: {
      border: `4px solid ${theme.primary80}`,
      backgroundColor: `${theme.secondary}`,
      color: `${theme.tertiary}`,
      fontFamily: "var(--primaryFont)",
      fontWeight: 500,
      transition: "border 0.2s ease-in-out",
      "&:focus": {
        border: `4px solid ${theme.primary600}`,
      },
    },
    navMenu: {
      fontSize: "2.5rem",
      color: theme.tertiary,
      cursor: "pointer",
      transform: "translateY(-10px)",
      transition: "color 0.3s",
      position: "fixed",
      top: "30px",
      right: "30px",
      "&:hover": {
        color: theme.primary,
      },
      [t.breakpoints.down("sm")]: {
        fontSize: "2.5rem",
      },
      [t.breakpoints.down("xs")]: {
        fontSize: "2rem",
      },
    },
    MuiDrawer: {
      padding: "0em 1.8em",
      width: "14em",
      fontFamily: " var(--primaryFont)",
      fontStyle: " normal",
      fontWeight: " normal",
      fontSize: " 24px",
      background: theme.secondary,
      overflow: "hidden",
      borderTopRightRadius: "40px",
      borderBottomRightRadius: "40px",
      [t.breakpoints.down("sm")]: {
        width: "12em",
      },
    },
    closebtnIcon: {
      fontSize: "2rem",
      fontWeight: "bold",
      cursor: "pointer",
      color: theme.primary,
      position: "absolute",
      right: 40,
      top: 30,
      transition: "color 0.2s",
      "&:hover": {
        color: theme.tertiary,
      },
      [t.breakpoints.down("sm")]: {
        right: 20,
        top: 20,
      },
    },
    drawerItem: {
      margin: "2rem auto",
      borderRadius: "78.8418px",
      background: theme.secondary,
      color: theme.primary,
      width: "100%",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 30px",
      boxSizing: "border-box",
      border: "2px solid",
      borderColor: theme.primary,
      transition: "background-color 0.2s, color 0.2s",
      "&:hover": {
        background: theme.primary,
        color: theme.secondary,
      },
      [t.breakpoints.down("sm")]: {
        width: "100%",
        padding: "0 25px",
        height: "55px",
      },
    },
    home: {
      color: theme.secondary,
      position: "absolute",
      top: 25,
      left: 25,
      padding: "7px",
      borderRadius: "50%",
      boxSizing: "content-box",
      fontSize: "2rem",
      cursor: "pointer",
      boxShadow:
        theme.type === "dark"
          ? "3px 3px 6px #ffffff40, -3px -3px 6px #00000050"
          : "3px 3px 6px #ffffff40, -3px -3px 6px #00000050",
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        color: theme.tertiary,
        transform: "scale(1.1)",
      },
      [t.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
      },
    },
    drawerLinks: {
      fontFamily: "var(--primaryFont)",
      fontSize: "1.3rem",
      fontWeight: 600,
      marginLeft: "15px",
      [t.breakpoints.down("sm")]: {
        fontSize: "1.125rem",
      },
    },
    drawerIcon: {
      fontSize: "1.6rem",
      [t.breakpoints.down("sm")]: {
        fontSize: "1.385rem",
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={`navbar ${isScrolled ? "scrolled" : ""}`}>
      <div className="navbar--container">
        {window.location.pathname === "/" ? (
          <h1 className="title-right" style={{ color: theme.secondary }}>
            Sazu{" "}
          </h1>
        ) : (
          <Link to="/">
            <AiOutlineHome className={`home-right ${classes.home}`} />
          </Link>
        )}

        {window.location.pathname === "/" && (
          <div className="checkbox-container">
            <label className="checkbox">
              <input
                type="checkbox"
                checked={colorTheme === "red"}
                onChange={() => handleCheckboxChange("red")}
              />
              <span className="checkmark red"></span>
            </label>

            <label className="checkbox">
              <input
                type="checkbox"
                checked={colorTheme === "green"}
                onChange={() => handleCheckboxChange("green")}
              />
              <span className="checkmark green"></span>
            </label>

            <label className="checkbox">
              <input
                type="checkbox"
                checked={colorTheme === "blue"}
                onChange={() => handleCheckboxChange("blue")}
              />
              <span className="checkmark blue"></span>
            </label>

            <label className="checkbox">
              <input
                type="checkbox"
                checked={colorTheme === "yellow"}
                onChange={() => handleCheckboxChange("yellow")}
              />
              <span className="checkmark yellow"></span>
            </label>

            <label className="checkbox">
              <input
                type="checkbox"
                checked={colorTheme === "purple"}
                onChange={() => handleCheckboxChange("purple")}
              />
              <span className="checkmark purple"></span>
            </label>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={colorTheme === "bw"}
                onChange={() => handleCheckboxChange("bw")}
              />
              <span className="checkmark bw"></span>
            </label>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={colorTheme === "orange"}
                onChange={() => handleCheckboxChange("orange")}
              />
              <span className="checkmark orange"></span>
            </label>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={colorTheme === "pink"}
                onChange={() => handleCheckboxChange("pink")}
              />
              <span className="checkmark pink"></span>
            </label>
          </div>
        )}

        <label className="container">
          <input
            type="checkbox"
            id="toggle"
            checked={isDarkMode}
            onChange={handleToggle}
          />
          <span className="slider round">
            <div className="background"></div>
            <div className="star"></div>
            <div className="star"></div>
          </span>
        </label>

        <IoMenuSharp
          className={`menu-burguer ${classes.navMenu}`}
          onClick={handleDrawerOpen}
          aria-label="Menu"
          id="navicon"
        />
      </div>
      <Drawer
        variant="temporary"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleDrawerClose();
          } else if (reason !== "escapeKeyDown") {
            handleDrawerClose();
          }
        }}
        anchor="left"
        open={open}
        classes={{ paper: classes.MuiDrawer }}
        className="drawer"
        disableScrollLock={true}
      >
        <div className="div-closebtn">
          <CloseIcon
            onClick={handleDrawerClose}
            onKeyDown={(e) => {
              if (e.key === " " || e.key === "Enter") {
                e.preventDefault();
                handleDrawerClose();
              }
            }}
            className={classes.closebtnIcon}
            role="button"
            tabIndex="0"
            aria-label="Close"
          />
        </div>
        <br />

        <div onClick={handleDrawerClose}>
          <div className="navLink--container">
            <NavLink to="/" smooth={true} spy="true" duration={2000}>
              <div
                className={classes.drawerItem}
                data-aos="flip-left"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-delay="0"
              >
                <IoHomeSharp className={classes.drawerIcon} />
                <span className={classes.drawerLinks}>Home</span>
              </div>
            </NavLink>

            <NavLink to="/#about" smooth={true} spy="true" duration={2000}>
              <div
                className={classes.drawerItem}
                data-aos="flip-left"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-delay="200"
              >
                <FaUser className={classes.drawerIcon} />
                <span className={classes.drawerLinks}>Sobre</span>
              </div>
            </NavLink>

            <NavLink to="/#education" smooth={true} spy="true" duration={2000}>
              <div
                className={classes.drawerItem}
                data-aos="flip-left"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-delay="400"
              >
                <FaUserGraduate className={classes.drawerIcon} />
                <span className={classes.drawerLinks}>Graduação</span>
              </div>
            </NavLink>

            <NavLink to="/#skills" smooth={true} spy="true" duration={2000}>
              <div
                className={classes.drawerItem}
                data-aos="flip-left"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-delay="600"
              >
                <FaReact className={classes.drawerIcon} />
                <span className={classes.drawerLinks}>Tecnologias</span>
              </div>
            </NavLink>

            <NavLink to="/#projects" smooth={true} spy="true" duration={2000}>
              <div
                className={classes.drawerItem}
                data-aos="flip-left"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-delay="800"
              >
                <FaFolderOpen className={classes.drawerIcon} />
                <span className={classes.drawerLinks}>Projetos</span>
              </div>
            </NavLink>

            <NavLink
              to="/#referencias"
              smooth={true}
              spy="true"
              duration={4000}
            >
              <div
                className={classes.drawerItem}
                data-aos="flip-left"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-delay="1000"
              >
                <VscFeedback className={classes.drawerIcon} />
                <span className={classes.drawerLinks}>Referências</span>
              </div>
            </NavLink>

            <NavLink to="/#experience" smooth={true} spy="true" duration={2000}>
              <div
                className={classes.drawerItem}
                data-aos="flip-left"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-delay="1200"
              >
                <FaBook className={classes.drawerIcon} />
                <span className={classes.drawerLinks}>Experiência</span>
              </div>
            </NavLink>
            <NavLink to="/#services" smooth={true} spy="true" duration={2000}>
              <div
                className={classes.drawerItem}
                data-aos="flip-left"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-delay="1400"
              >
                <MdOutlineLaptopMac className={classes.drawerIcon} />
                <span className={classes.drawerLinks}>Serviços</span>
              </div>
            </NavLink>
            <NavLink to="/#contacts" smooth={true} spy="true" duration={2000}>
              <div
                className={classes.drawerItem}
                data-aos="flip-left"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-delay="1600"
              >
                <MdPhone className={classes.drawerIcon} />
                <span className={classes.drawerLinks}>Contato</span>
              </div>
            </NavLink>
          </div>
        </div>
      </Drawer>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: isScrolled ? "center" : "left",
        }}
        open={success}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackbarContent
          action={
            <React.Fragment>
              <IconButton
                size="medium"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </React.Fragment>
          }
          style={{
            backgroundColor:
              colorTheme === "yellow"
                ? "goldenrod"
                : colorTheme === "bw" && !isDarkMode
                ? "grey"
                : colorTheme === "bw"
                ? "black"
                : colorTheme,
            color: "white",
            fontSize: "1rem",
            fontFamily: "var(--primaryFont)",
          }}
          message={
            !isDarkMode ? "Light mode eu não gosto 😑" : "Dark mode é melhor 😎"
          }
        />
      </Snackbar>
    </div>
  );
}

export default Navbar;
