import React, { useContext, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./PartidaCPJ.css";
import { Navbar, Footer, PartidaCard } from "../../components/index.js";
import { ThemeContext } from "../../contexts/ThemeContext.js";
import { partidaSextaData } from "../../data/partidaSextaData.js";
import { FaArrowLeft } from "react-icons/fa6";

function PartidaCPJ() {
  const { theme } = useContext(ThemeContext);
  const { idPartida } = useParams();
  const [partidaInfo, sePartidaInfo] = useState(
    partidaSextaData[idPartida - 1]
  );

  console.log("IS É ESSE", idPartida);

  return (
    <div className="partidaPage" style={{ backgroundColor: theme.secondary }}>
      <Helmet>
        <title>Futsal | CPJ</title>
      </Helmet>
      <Navbar />
      <div
        className="partidaPage-header"
        style={{ backgroundColor: theme.primary }}
      >
        <h1 style={{ color: theme.secondary }}>⚽️ PARTIDA 🥅</h1>
      </div>
      <div className="partidaPage-container">
        <div className="futsal-cont">
          <div className="sexta">
            <div className="header-partida">
            <Link to="/futsal-cpj" className="link-voltar">
              <button
                className={"voltar"}
                style={{
                  backgroundColor: theme.primary,
                  color: theme.secondary,
                }}
              >
                <FaArrowLeft className={"voltar-icone"} />
                Voltar para todas as partidas
              </button>
            </Link>
            <h2 style={{ color: theme.tertiary }}>
              Sexta-feira 🔸 {partidaInfo.day} 🔸 19:00 horas
            </h2>
            </div>
            <PartidaCard
              tela="partida"
              key={partidaInfo.id}
              id={partidaInfo.id}
              time1={partidaInfo.time1}
              time2={partidaInfo.time2}
              escalacaoTime1={partidaInfo.escalacaoTime1}
              escalacaoTime2={partidaInfo.escalacaoTime2}
              golsTime1={partidaInfo.golsTime1}
              golsTime2={partidaInfo.golsTime2}
              day={partidaInfo.day}
              artilheiro={partidaInfo.artilheiro}
              artilheiroFoto={partidaInfo.artilheiroFoto}
              artilheiroTime={partidaInfo.artilheiroTime}
              mvp={partidaInfo.mvp}
              mvpFoto={partidaInfo.mvpFoto}
              mvpTime={partidaInfo.mvpTime}
              pior={partidaInfo.pior}
              piorFoto={partidaInfo.piorFoto}
              piorTime={partidaInfo.piorTime}
              historicoTime1={partidaInfo.historicoTime1}
              historicoTime2={partidaInfo.historicoTime2}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PartidaCPJ;
