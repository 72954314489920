import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./NewCPJ.css";
import { Navbar, Footer, PartidaCard } from "../../components/index.js";
import { ThemeContext } from "../../contexts/ThemeContext.js";
import { partidaSextaData } from "../../data/partidaSextaData.js";
import { FaArrowLeft } from "react-icons/fa6";

function NewCPJ() {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="newCPJ" style={{ backgroundColor: theme.secondary }}>
      <Helmet>
        <title>Futsal | CPJ</title>
      </Helmet>
      <Navbar />
      <div className="newCPJ-header" style={{ backgroundColor: theme.primary }}>
        <h1 style={{ color: theme.secondary }}>⚽️ NEW ✢ </h1>
      </div>
      <div className="newCPJ-container">
        <div className="futsal-cont">
          <div className="header-partida">
            <Link to="/futsal-cpj" className="link-voltar">
              <button
                className={"voltar"}
                style={{
                  backgroundColor: theme.primary,
                  color: theme.secondary,
                }}
              >
                <FaArrowLeft className={"voltar-icone"} />
                Voltar para todas as partidas
              </button>
            </Link>
            <h2 style={{ color: theme.tertiary }}>
              Cadastrar Jogador
            </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NewCPJ;
