import resume from "../assets/pdf/cvMatheusGoulart2024.pdf";

export const headerData = {
  name: "Matheus Goulart de Freitas",
  title: "Engenheiro de Computação",
  titleSecundary: "Desenvolvedor Full Stack",
  desciption:
    "Posso construir e desenvolver o sistema ou site que você quiser! 😃",
  desciptionSecundary:
    "Só não faço aplicativos para celulares e nem robô de Pix. 🥴",
  image: "https://media.licdn.com/dms/image/v2/C4D03AQFjk_uQg9NDpg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1641829004979?e=1729123200&v=beta&t=OOrWJXfHM5DFamR8kr0C8NlB1eLv5mhNfi7ykRXJ2Tk",
  resumePdf: resume,
};
