import React, { createContext, useState } from "react";
import { themeData } from "../data/themeData";
export const ThemeContext = createContext();

function ThemeContextProvider(props) {
  // eslint-disable-next-line
  const [theme, setTheme] = useState(themeData.theme.blueDark);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const setHandleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const switchTheme = (isDarkMode, color = "purple") => {
    const themeKey = `${color}${isDarkMode ? "Dark" : "Light"}`;
    const themeColor = themeData.theme[themeKey] || themeData.theme.blueDark; // Default para purpleLight se a cor não for encontrada

    setTheme(themeColor);
  };

  const value = { theme, drawerOpen, setHandleDrawer, switchTheme };
  return (
    <ThemeContext.Provider value={value}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export default ThemeContextProvider;
