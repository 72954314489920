import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import "./FeedbackPage.css";
import { Navbar, Footer, ContactsFeedback } from "../../components/index.js";
import { ThemeContext } from "../../contexts/ThemeContext.js";

function FeedbackPage() {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="projectPage" style={{ backgroundColor: theme.secondary }}>
      <Helmet>
        <title>Matheus Goulart | Feedback</title>
      </Helmet>
      <Navbar />
      <div
        className="projectPage-header"
        style={{ backgroundColor: theme.primary }}
      >

        <h1 style={{ color: theme.secondary }}>Feedback ✅</h1>
      </div>
      <ContactsFeedback />
      <Footer />
    </div>
  );
}

export default FeedbackPage;
