export const experienceData = [
    {
        id: 1,
        company: 'KMM by Nstech',
        jobtitle: 'Desenvolvedor Full Stack Pleno',
        startYear: '2022',
        type: 'CLT',
        endYear: 'Atualmente'
    },
    {
        id: 2,
        company: 'Mundo Mapping',
        jobtitle: 'Desenvolvedor Full Stack Pleno',
        startYear: '2023',
        type: 'Freelancer',
        endYear: '2023'
    },
    {
        id: 3,
        company: 'Lösung Tecnologia',
        jobtitle: 'Desenvolvedor Frontend',
        startYear: 'Janeiro/2022',
        type: 'PJ',
        endYear: 'Julho/2022'
    },
    {
        id: 4,
        company: 'Mapa360',
        jobtitle: 'Desenvolvedor Web',
        startYear: 'Agosto/2020',
        type: 'CLT',
        endYear: 'Dezembro/2022'
    },
    {
        id: 5,
        company: 'Agência Ade',
        jobtitle: 'Desenvolvedor Frontend Jr', 
        startYear: 'Janeiro/2020',
        type: 'CLT',
        endYear: 'Agosto/2020'
    },
    {
        id: 6,
        company: 'TratorCase',
        jobtitle: 'Desenvolvedor Full Stack Pleno',
        startYear: 'Janeiro/2023',
        type: 'Freelancer',
        endYear: 'Novembro/2023'
    },
    {
        id: 7,
        company: 'Fleming',
        jobtitle: 'Desenvolvedor Full Stack Pleno',
        startYear: 'Janeiro/2022',
        type: 'Freelancer',
        endYear: 'Outubro/2022'
    },
    {
        id: 8,
        company: 'Colégio APAM',
        jobtitle: 'Instrutor de Informática',
        startYear: 'Março/2020',
        type: 'Freelancer',
        endYear: 'Fevereiro/2020'
    }
]