import pokedex from "../assets/png/pokedex.png";

export const projectsData = [
  {
    id: 1,
    projectName: "SPLASH Estética Automotiva",
    projectDesc:
      "Esse é um sistema de agendamento de horário para lavagem de veículos, foi construído para atender a necessidade da empresa em possuir um controle de agendamentos, dashboard de faturamento e programa de fidelidade.",
    tags: ["React JS", "Node JS", "MySQL", "Javascript", "AWS"],
    code: "https://github.com/GoulartSazu",
    demo: "https://splashpg.com.br/",
    image:
      "https://www.tratorcase.com.br/wp-content/uploads/2024/08/splash-img.png",
  },
  {
    id: 2,
    projectName: "TratorCase",
    projectDesc:
      "Site criado para a empresa de TratorCase especializada em máquinas agrícolas. Foi desenvolvido um dashboard completo em WordPress, dessa maneira o cliente pode trocar qualquer imagem ou texto do site, pode adicionar ou remover produtos e editar as principais informações.",
    tags: ["HTML", "CSS", "Javascript", "WordPress", "PHP"],
    code: "https://github.com/GoulartSazu",
    demo: "https://www.tratorcase.com.br/index.html",
    image:
      "https://www.tratorcase.com.br/wp-content/uploads/2024/08/casetr.png",
  },
  {
    id: 3,
    projectName: "ALK Eventos",
    projectDesc:
      "ALK foi desenvolvido em 2022 enquanto estava na MAPA360, a empresa tem um diferencial de sites com layout modernos para todos seus clientes.",
    tags: ["HTML", "CSS", "Javascript", "WordPress", "PHP"],
    code: "https://mapa360.com.br/",
    demo: "https://www.alkeventos.com.br/",
    image: "https://www.tratorcase.com.br/wp-content/uploads/2024/08/alk.png",
  },
  {
    id: 4,
    projectName: "Pokedex",
    projectDesc:
      "A famosa pokedex que todo dev já deve ter feito na vida, esse projeto era um teste pra uma vaga de desenvolvedor frontend na empresa Losung. Deixei o repositório público no botão abaixo, hoje em dia tenho vontade de refatorar esse projeto pra servir de template para sistemas que tenham páginas de card.",
    tags: ["React JS", "Axios", "Styled Components", "Git"],
    code: "https://github.com/GoulartSazu/Teste-Losung/tree/main",
    demo: "https://www.linkedin.com/posts/matheus-goulart-de-freitas-08404219a_depois-de-realizar-um-projeto-teste-para-activity-6886734417918754817-MKCY?utm_source=share&utm_medium=member_desktop",
    image: pokedex,
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
