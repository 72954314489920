import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import "./FutsalCPJ.css";
import { Navbar, Footer, PartidaCard } from "../../components/index.js";
import { ThemeContext } from "../../contexts/ThemeContext.js";
import { partidaSextaData } from "../../data/partidaSextaData";
function FutsalCPJ() {
  const [search, setSearch] = useState("");
  const [partidasSexta, setPartidasSexta] = useState(partidaSextaData);
  const [partidasSabado, sePartidasSabado] = useState([]);
  const { theme } = useContext(ThemeContext);

  const useStyles = makeStyles((t) => ({
    search: {
      color: theme.tertiary,
      width: "40%",
      height: "2.75rem",
      outline: "none",
      border: "none",
      borderRadius: "5px",
      padding: "1.9rem",
      fontFamily: "'Noto Sans TC', sans-serif",
      fontWeight: 400,
      fontSize: "1.1rem",
      backgroundColor: theme.secondary,
      boxShadow:
        theme.type === "light"
          ? "1px 1px 10px #ffffff10, 1px 1px 10px #00000060"
          : "1px 1px 10px #ffffffbd, 1px 1px 10px #00000030",
      "&::placeholder": {
        color: theme.tertiary80,
      },
      [t.breakpoints.down("sm")]: {
        width: "350px",
      },
    },
    home: {
      color: theme.secondary,
      position: "absolute",
      top: 25,
      left: 25,
      padding: "7px",
      borderRadius: "50%",
      boxSizing: "content-box",
      fontSize: "2rem",
      cursor: "pointer",
      boxShadow:
        theme.type === "dark"
          ? "3px 3px 6px #ffffff40, -3px -3px 6px #00000050"
          : "3px 3px 6px #ffffff40, -3px -3px 6px #00000050",
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        color: theme.tertiary,
        transform: "scale(1.1)",
      },
      [t.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="futsalPage" style={{ backgroundColor: theme.secondary }}>
      <Helmet>
        <title>Futsal | CPJ</title>
      </Helmet>
      <Navbar />
      <div
        className="futsalPage-header"
        style={{ backgroundColor: theme.primary }}
      >
        <h1 style={{ color: theme.secondary }}>⚽️ FUTSAL CPJ 🥅</h1>
      </div>
      <div className="futsalPage-container">
        <div className="futsalPage-search">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Pesquisar por data"
            className={classes.search}
          />
        </div>
        <div className="futsal-cont">
          <div className="sexta">
            <h2 style={{ color: theme.tertiary }}>SEXTA-FEIRA</h2>
            {partidasSexta.map((game) => (
              <PartidaCard
                key={game.id}
                id={game.id}
                time1={game.time1}
                time2={game.time2}
                golsTime1={game.golsTime1}
                golsTime2={game.golsTime2}
                day={game.day}
                artilheiro={game.artilheiro}
                artilheiroFoto={game.artilheiroFoto}
                artilheiroTime={game.artilheiroTime}
                mvp={game.mvp}
                mvpFoto={game.mvpFoto}
                mvpTime={game.mvpTime}
                pior={game.pior}
                piorFoto={game.piorFoto}
                piorTime={game.piorTime}
                historicoTime1={game.historicoTime1}
                historicoTime2={game.historicoTime2}
              />
            ))}
          </div>
          <div className="sabado">
            <h2 style={{ color: theme.tertiary }}>SÁBADO</h2>
            {partidasSexta.map((game) => (
              <PartidaCard
                key={game.id}
                id={game.id}
                time1={game.time1}
                time2={game.time2}
                golsTime1={game.golsTime1}
                golsTime2={game.golsTime2}
                day={game.day}
                artilheiro={game.artilheiro}
                artilheiroFoto={game.artilheiroFoto}
                artilheiroTime={game.artilheiroTime}
                mvp={game.mvp}
                mvpFoto={game.mvpFoto}
                mvpTime={game.mvpTime}
                pior={game.pior}
                piorFoto={game.piorFoto}
                piorTime={game.piorTime}
                historicoTime1={game.historicoTime1}
                historicoTime2={game.historicoTime2}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FutsalCPJ;
