import React, { useContext, useState } from "react";
import { Snackbar, IconButton, SnackbarContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { AiOutlineSend } from "react-icons/ai";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { ThemeContext } from "../../contexts/ThemeContext";
import "./Contacts.css";

function ContactsFeedback() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [cargo, setCargo] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { theme } = useContext(ThemeContext);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const useStyles = makeStyles((t) => ({
    input: {
      border: `4px solid ${theme.primary80}`,
      backgroundColor: `${theme.secondary}`,
      color: `${theme.tertiary}`,
      fontFamily: "var(--primaryFont)",
      fontWeight: 500,
      transition: "border 0.2s ease-in-out",
      "&:focus": {
        border: `4px solid ${theme.primary600}`,
      },
    },
    message: {
      border: `4px solid ${theme.primary80}`,
      backgroundColor: `${theme.secondary}`,
      color: `${theme.tertiary}`,
      fontFamily: "var(--primaryFont)",
      fontWeight: 500,
      transition: "border 0.2s ease-in-out",
      "&:focus": {
        border: `4px solid ${theme.primary600}`,
      },
    },
    label: {
      backgroundColor: `${theme.secondary}`,
      color: `${theme.primary}`,
      fontFamily: "var(--primaryFont)",
      fontWeight: 600,
      fontSize: "0.9rem",
      padding: "0 5px",
      transform: "translate(25px,50%)",
      display: "inline-flex",
    },
    socialIcon: {
      width: "45px",
      height: "45px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "21px",
      backgroundColor: theme.primary,
      color: theme.secondary,
      transition: "250ms ease-in-out",
      "&:hover": {
        transform: "scale(1.1)",
        color: theme.secondary,
        backgroundColor: theme.tertiary,
      },
    },
    detailsIcon: {
      backgroundColor: theme.primary,
      color: theme.secondary,
      borderRadius: "50%",
      width: "45px",
      height: "45px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "23px",
      transition: "250ms ease-in-out",
      flexShrink: 0,
      "&:hover": {
        transform: "scale(1.1)",
        color: theme.secondary,
        backgroundColor: theme.tertiary,
      },
    },
    submitBtn: {
      backgroundColor: theme.primary,
      color: theme.secondary,
      transition: "250ms ease-in-out",
      "&:hover": {
        transform: "scale(1.08)",
        color: theme.secondary,
        backgroundColor: theme.tertiary,
      },
    },
  }));
  const classes = useStyles();
  
  const handleContactForm = (e) => {
    e.preventDefault();

    if (name && message) {
      const responseData = {
        fdb_placa: 'DEV',
        fdb_stars: 5,
        fdb_message: `Nome: ${name}--Cargo: ${cargo}--Mensagem: ${message}`,
      };

      axios.post('https://splashpg.com.br/api/feedback', responseData).then((res) => {
        setSuccess(true);
        setSuccessMessage("Sucesso! Muito obrigado " + name + " ✅")
        setErrMsg("");
        setName("");
        setCargo("");
        setMessage("");
        setOpen(false);
      });
    } else {
      setErrMsg("Preencha o nome e mensagem 😑");
      setOpen(true);
    }
  };

  return (
    <div
      className="contacts feedb"
      id="contacts"
      style={{ backgroundColor: theme.secondary }}
    >
      <div className="contacts--container feedback">
        <h1 style={{ color: theme.primary }}>
          Sinta-se a vontade para escrever pontos positivos ou negativos =]
        </h1>
        <div className="contacts-body">
          <div className="contacts-form">
            <form onSubmit={handleContactForm}>
              <div className="feed">
                <div className="input-container">
                  <label htmlFor="Name" className={classes.label}>
                    Nome
                  </label>
                  <input
                    placeholder="Fulano de Tal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    name="Name"
                    className={`form-input ${classes.input}`}
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="Cargo" className={classes.label}>
                    Cargo ou Empresa
                  </label>
                  <input
                    placeholder="Exemplo: CTO Amazon"
                    value={cargo}
                    onChange={(e) => setCargo(e.target.value)}
                    type="Cargo"
                    name="Cargo"
                    className={`form-input ${classes.input}`}
                  />
                </div>
              </div>
              <div className="input-container">
                <label htmlFor="Message" className={classes.label}>
                  Mensagem
                </label>
                <textarea
                  placeholder="Digite sua mensagem"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  type="text"
                  name="Message"
                  className={`form-message ${classes.message}`}
                />
              </div>

              <div className="submit-btn">
                <button type="submit" className={classes.submitBtn}>
                  <p>{!success ? "Enviar" : "Enviar"}</p>
                  <div className="submit-icon">
                    <AiOutlineSend
                      className="send-icon"
                      style={{
                        animation: !success
                          ? "initial"
                          : "fly 0.8s linear both",
                        position: success ? "absolute" : "initial",
                      }}
                    />
                    <IoCheckmarkDoneCircleSharp 
                      className="success-icon"
                      style={{
                        display: !success ? "none" : "inline-flex",
                        opacity: !success ? "0" : "1",
                      }}
                    />
                  </div>
                </button>
              </div>
            </form>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={open}
              autoHideDuration={40000}
              onClose={handleClose}
            >
              <SnackbarContent
                action={
                  <React.Fragment>
                    <IconButton
                      size="medium"
                      aria-label="close"
                      color="inherit"
                      onClick={handleClose}
                    >
                      <CloseIcon fontSize="medium" />
                    </IconButton>
                  </React.Fragment>
                }
                style={{
                  backgroundColor: theme.primary,
                  color: theme.secondary,
                  fontSize: "1rem",
                  fontFamily: "var(--primaryFont)",
                }}
                message={errMsg}
              />
            </Snackbar>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={success}
              autoHideDuration={5000}
              onClose={handleClose}
            >
              <SnackbarContent
                action={
                  <React.Fragment>
                    <IconButton
                      size="medium"
                      aria-label="close"
                      color="inherit"
                      onClick={handleClose}
                    >
                      <CloseIcon fontSize="medium" />
                    </IconButton>
                  </React.Fragment>
                }
                style={{
                  backgroundColor: "green",
                  color: "white",
                  fontSize: "1rem",
                  fontFamily: "var(--primaryFont)",
                }}
                message={successMessage}
              />
            </Snackbar>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactsFeedback;
