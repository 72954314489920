import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  Main,
  BlogPage,
  ProjectPage,
  ExperiencePage,
  FeedbackPage,
  FutsalCPJ,
  PartidaCPJ,
  NewCPJ
} from "./pages";
import { BackToTop } from "./components";
import ScrollToTop from "./utils/ScrollToTop";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  AOS.init();

  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/blog" exact component={BlogPage} />
          <Route path="/projects" exact component={ProjectPage} />
          <Route path="/experience" exact component={ExperiencePage} />
          <Route path="/feedback" exact component={FeedbackPage} />
          <Route path="/futsal-cpj" exact component={FutsalCPJ} />
          <Route path="/futsal-cpj/cadastros" exact component={NewCPJ} />
          <Route
            path="/futsal-cpj/partida/:idPartida"
            exact
            component={PartidaCPJ}
          />
          <Redirect to="/" />
        </Switch>
      </Router>
      <BackToTop />
    </div>
  );
}

export default App;
