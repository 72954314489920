import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./ExperiencePage.css";
import { Navbar, Footer } from "../../components";
import ExperienceCard from "../../components/Experience/ExperienceCard.js";
import { ThemeContext } from "../../contexts/ThemeContext";
import { experienceData } from "../../data/experienceData";

function ExperiencePage() {
  const [search, setSearch] = useState("");
  const { theme } = useContext(ThemeContext);

  const filteredArticles = experienceData.filter((project) => {
    const content = project.company + project.jobtitle + project.type;
    return content.toLowerCase().includes(search.toLowerCase());
  });

  const useStyles = makeStyles((t) => ({
    search: {
      color: theme.tertiary,
      width: "40%",
      height: "2.75rem",
      outline: "none",
      border: "none",
      borderRadius: "5px",
      padding: "1.9rem",
      fontFamily: "'Noto Sans TC', sans-serif",
      fontWeight: 400,
      fontSize: "1.1rem",
      backgroundColor: theme.secondary,
      boxShadow:
        theme.type === "light"
          ? "1px 1px 10px #ffffff10, 1px 1px 10px #00000060"
          : "1px 1px 10px #ffffffbd, 1px 1px 10px #00000030",
      "&::placeholder": {
        color: theme.tertiary80,
      },
      [t.breakpoints.down("sm")]: {
        width: "350px",
      },
    },
    home: {
      color: theme.secondary,
      position: "absolute",
      top: 25,
      left: 25,
      padding: "7px",
      borderRadius: "50%",
      boxSizing: "content-box",
      fontSize: "2rem",
      cursor: "pointer",
      boxShadow:
        theme.type === "dark"
          ? "3px 3px 6px #ffffff40, -3px -3px 6px #00000050"
          : "3px 3px 6px #ffffff40, -3px -3px 6px #00000050",
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        color: theme.tertiary,
        transform: "scale(1.1)",
      },
      [t.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="projectPage" style={{ backgroundColor: theme.secondary }}>
      <Helmet>
        <title>Matheus Goulart | Experiência</title>
      </Helmet>
      <Navbar />
      <div
        className="projectPage-header"
        style={{ backgroundColor: theme.primary }}
      >
        <h1 style={{ color: theme.secondary }}>Experiência 📚</h1>
      </div>
      <div className="projectPage-container">
        <div className="projectPage-search">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Pesquise por cargo, empresa ou tipo de contrato..."
            className={classes.search}
          />
        </div>
        <div className="project-container">
          <Grid
            className="project-grid"
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {/* {filteredArticles.map((project) => (
              <SingleProject
                theme={theme}
                key={project.id}
                id={project.id}
                name={project.projectName}
                desc={project.projectDesc}
                tags={project.tags}
                code={project.code}
                demo={project.demo}
                image={project.image}
              />
            ))} */}
            {filteredArticles.map((exp) => (
              <ExperienceCard
                key={exp.id}
                id={exp.id}
                jobtitle={exp.jobtitle}
                company={exp.company}
                type={exp.type}
                startYear={exp.startYear}
                endYear={exp.endYear}
              />
            ))}
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ExperiencePage;
