import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import { HiArrowRight } from "react-icons/hi";
import "./Experience.css";
import { experienceData } from "../../data/experienceData";
import ExperienceCard from "./ExperienceCard";
import { Link } from 'react-router-dom'

function Experience() {
  const { theme } = useContext(ThemeContext);
  const useStyles = makeStyles(() => ({
    viewAllBtn: {
      color: theme.tertiary,
      backgroundColor: theme.primary,
      "&:hover": {
        color: theme.secondary,
        backgroundColor: theme.primary,
      },
    },
    viewArr: {
      color: theme.tertiary,
      backgroundColor: theme.secondary70,
      width: "40px",
      height: "40px",
      padding: "0.5rem",
      fontSize: "1.05rem",
      borderRadius: "50%",
      cursor: "pointer",
      "&:hover": {
        color: theme.tertiary,
        backgroundColor: theme.secondary,
      },
    },
  }));

  const classes = useStyles();
  return (
    <div
      className="experience"
      id="experience"
      style={{ backgroundColor: theme.secondary }}
    >
      <div className="experience-body">
        <div className="experience-image">
          <img src={theme.expimg} alt="" />
        </div>
        <div className="experience-description">
          <h1 style={{ color: theme.primary }}>Experiência</h1>
          {experienceData.slice(0, 4).map((exp) => (
            <ExperienceCard
              key={exp.id}
              id={exp.id}
              jobtitle={exp.jobtitle}
              company={exp.company}
              type={exp.type}
              startYear={exp.startYear}
              endYear={exp.endYear}
            />
          ))}
          {experienceData.length > 3 && (
            <div className="experience--viewAll">
              <Link to="/experience">
                <button className={classes.viewAllBtn}>
                  Ver todas as experiências {' '}
                  <HiArrowRight className={classes.viewArr} />
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Experience;
