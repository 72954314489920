export const socialsData = {
    github: 'https://github.com/GoulartSazu',
    facebook: 'https://www.facebook.com/matheus.gooulart.7/',
    linkedIn: 'https://www.linkedin.com/in/matheus-goulart-de-freitas-08404219a/',
    instagram: 'https://www.instagram.com/_goulartmatheus/',
    codepen: '',
    twitter: '',
    reddit: '',
    blogger: '',
    medium: 'https://medium.com/@sreerag.rajan5',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}