import sazuPhoto from "../assets/png/players-cpj/sazu.jpg";

export const partidaSextaData = [
  {
    id: 1,
    time1: "AMARELO",
    time2: "PRETO",
    escalacaoTime1: [
      {
        id: 1,
        jogadorNome: "Matheus Goulart",
        jogadorFoto: sazuPhoto,
        gols: 16,
        faltas: 1,
      },
      {
        id: 2,
        jogadorNome: "Thiago Pinheiro",
        jogadorFoto: sazuPhoto,
        gols: 8,
        faltas: 2,
      },
      {
        id: 3,
        jogadorNome: "Lucas Silva",
        jogadorFoto: sazuPhoto,
        gols: 4,
        faltas: 3,
      },
      {
        id: 4,
        jogadorNome: "Bruno Almeida",
        jogadorFoto: sazuPhoto,
        gols: 2,
        faltas: 0,
      },
      {
        id: 5,
        jogadorNome: "Pedro Santos",
        jogadorFoto: sazuPhoto,
        gols: 5,
        faltas: 1,
      },
      {
        id: 6,
        jogadorNome: "Gabriel Lima",
        jogadorFoto: sazuPhoto,
        gols: 7,
        faltas: 2,
      },
      {
        id: 7,
        jogadorNome: "Felipe Costa",
        jogadorFoto: sazuPhoto,
        gols: 3,
        faltas: 4,
      },
      {
        id: 8,
        jogadorNome: "Ricardo Alves",
        jogadorFoto: sazuPhoto,
        gols: 6,
        faltas: 2,
      },
      {
        id: 9,
        jogadorNome: "João Oliveira",
        jogadorFoto: sazuPhoto,
        gols: 1,
        faltas: 1,
      },
    ],
    escalacaoTime2: [
      {
        id: 10,
        jogadorNome: "Carlos Pereira",
        jogadorFoto: sazuPhoto,
        gols: 9,
        faltas: 0,
      },
      {
        id: 11,
        jogadorNome: "André Mendes",
        jogadorFoto: sazuPhoto,
        gols: 3,
        faltas: 2,
      },
      {
        id: 12,
        jogadorNome: "Rafael Souza",
        jogadorFoto: sazuPhoto,
        gols: 7,
        faltas: 1,
      },
      {
        id: 13,
        jogadorNome: "Diego Araújo",
        jogadorFoto: sazuPhoto,
        gols: 5,
        faltas: 3,
      },
      {
        id: 14,
        jogadorNome: "Victor Martins",
        jogadorFoto: sazuPhoto,
        gols: 2,
        faltas: 2,
      },
      {
        id: 15,
        jogadorNome: "Marcelo Lima",
        jogadorFoto: sazuPhoto,
        gols: 4,
        faltas: 0,
      },
      {
        id: 16,
        jogadorNome: "Renato Silva",
        jogadorFoto: sazuPhoto,
        gols: 6,
        faltas: 1,
      },
      {
        id: 17,
        jogadorNome: "Henrique Borges",
        jogadorFoto: sazuPhoto,
        gols: 8,
        faltas: 3,
      },
    ],
    golsTime1: 14,
    golsTime2: 12,
    day: "06/09/2024",
    artilheiro: "Matheus Goulart",
    artilheiroFoto: sazuPhoto,
    artilheiroTime: "time1",
    mvp: "Lucas Pikunas",
    mvpFoto: sazuPhoto,
    mvpTime: "time1",
    pior: "Rafael Ianke",
    piorFoto: sazuPhoto,
    piorTime: "time2",
    historicoTime1: ["DERROTA", "VITORIA", "EMPATE", "VITORIA", "VITORIA","DERROTA", "VITORIA", "EMPATE", "VITORIA", "VITORIA"],
    historicoTime2: ["VITORIA", "DERROTA", "EMPATE", "DERROTA", "DERROTA","VITORIA", "DERROTA", "EMPATE", "DERROTA", "DERROTA"].reverse(),
  },
  {
    id: 2,
    time1: "AMARELO",
    time2: "PRETO",
    piorTime: "time1",
    artilheiroTime: "time2",
    mvpTime: "time2",
    golsTime1: 10,
    golsTime2: 10,
    day: "13/09/2024",
    artilheiro: "João Silva",
    artilheiroFoto: sazuPhoto,
    mvp: "Lucas Pikunas",
    mvpFoto: sazuPhoto,
    pior: "Carlos",
    piorFoto: sazuPhoto,
    historicoTime1: ["VITORIA", "VITORIA", "DERROTA", "EMPATE", "VITORIA"],
    historicoTime2: ["EMPATE", "DERROTA", "VITORIA", "VITORIA", "EMPATE"],
  },
  {
    id: 3,
    time1: "AMARELO",
    time2: "PRETO",
    piorTime: "time2",
    artilheiroTime: "time2",
    mvpTime: "time1",
    golsTime1: 7,
    golsTime2: 5,
    day: "20/09/2024",
    artilheiro: "Pedro Mendes",
    artilheiroFoto: sazuPhoto,
    mvp: "Mass Gui",
    mvpFoto: sazuPhoto,
    pior: "Felipe",
    piorFoto: sazuPhoto,
    historicoTime1: ["VITORIA", "EMPATE", "DERROTA", "VITORIA", "VITORIA"],
    historicoTime2: ["DERROTA", "DERROTA", "VITORIA", "EMPATE", "DERROTA"],
  },
  {
    id: 4,
    time1: "AMARELO",
    time2: "PRETO",
    golsTime1: 9,
    piorTime: "time1",
    artilheiroTime: "time1",
    mvpTime: "time2",
    golsTime2: 6,
    day: "27/09/2024",
    artilheiro: "Lucas Costa",
    artilheiroFoto: sazuPhoto,
    mvp: "Sazu Futsal",
    mvpFoto: sazuPhoto,
    pior: "Ricardo",
    piorFoto: sazuPhoto,
    historicoTime1: ["VITORIA", "VITORIA", "DERROTA", "DERROTA", "VITORIA"],
    historicoTime2: ["DERROTA", "EMPATE", "DERROTA", "VITORIA", "EMPATE"],
  },
  {
    id: 5,
    time1: "AMARELO",
    time2: "PRETO",
    golsTime1: 11,
    golsTime2: 10,
    day: "04/10/2024",
    artilheiro: "Thiago Lima",
    artilheiroFoto: sazuPhoto,
    mvp: "Felipão Buarque",
    mvpFoto: sazuPhoto,
    pior: "Bruno",
    piorFoto: sazuPhoto,
    historicoTime1: ["VITORIA", "EMPATE", "VITORIA", "VITORIA", "DERROTA"],
    historicoTime2: ["EMPATE", "VITORIA", "DERROTA", "VITORIA", "EMPATE"],
  },
  {
    id: 6,
    time1: "AMARELO",
    time2: "PRETO",
    golsTime1: 8,
    golsTime2: 9,
    day: "11/10/2024",
    artilheiro: "Gabriel Souza",
    artilheiroFoto: sazuPhoto,
    mvp: "Matheus Freitas",
    mvpFoto: sazuPhoto,
    pior: "Henrique",
    piorFoto: sazuPhoto,
    historicoTime1: ["DERROTA", "DERROTA", "EMPATE", "VITORIA", "DERROTA"],
    historicoTime2: ["VITORIA", "VITORIA", "DERROTA", "EMPATE", "VITORIA"],
  },
  {
    id: 7,
    time1: "AMARELO",
    time2: "PRETO",
    golsTime1: 6,
    golsTime2: 8,
    day: "18/10/2024",
    artilheiro: "André Santos",
    artilheiroFoto: sazuPhoto,
    mvp: "Ney Mato",
    mvpFoto: sazuPhoto,
    pior: "Diego",
    piorFoto: sazuPhoto,
    historicoTime1: ["DERROTA", "VITORIA", "EMPATE", "DERROTA", "VITORIA"],
    historicoTime2: ["VITORIA", "VITORIA", "DERROTA", "VITORIA", "EMPATE"],
  },
  {
    id: 8,
    time1: "AMARELO",
    time2: "PRETO",
    golsTime1: 12,
    golsTime2: 14,
    day: "25/10/2024",
    artilheiro: "Fernando Alves",
    artilheiroFoto: sazuPhoto,
    mvp: "Neymar Silva",
    mvpFoto: sazuPhoto,
    pior: "José",
    piorFoto: sazuPhoto,
    historicoTime1: ["VITORIA", "EMPATE", "DERROTA", "VITORIA", "DERROTA"],
    historicoTime2: ["EMPATE", "VITORIA", "DERROTA", "EMPATE", "VITORIA"],
  },
  {
    id: 9,
    time1: "AMARELO",
    time2: "PRETO",
    golsTime1: 9,
    golsTime2: 7,
    day: "01/11/2024",
    artilheiro: "Vitor Nunes",
    artilheiroFoto: sazuPhoto,
    mvp: "Vitor Nunes",
    mvpFoto: sazuPhoto,
    pior: "Leandro",
    piorFoto: sazuPhoto,
    historicoTime1: ["EMPATE", "VITORIA", "VITORIA", "DERROTA", "VITORIA"],
    historicoTime2: ["DERROTA", "DERROTA", "VITORIA", "EMPATE", "DERROTA"],
  },
  {
    id: 10,
    time1: "AMARELO",
    time2: "PRETO",
    golsTime1: 5,
    golsTime2: 10,
    day: "08/11/2024",
    artilheiro: "Renato Pereira",
    artilheiroFoto: sazuPhoto,
    mvp: "Yuri Alberto",
    mvpFoto: sazuPhoto,
    pior: "Marcelo",
    piorFoto: sazuPhoto,
    historicoTime1: ["DERROTA", "EMPATE", "VITORIA", "VITORIA", "DERROTA"],
    historicoTime2: ["VITORIA", "DERROTA", "EMPATE", "VITORIA", "VITORIA"],
  },
  {
    id: 11,
    time1: "AMARELO",
    time2: "PRETO",
    golsTime1: 11,
    golsTime2: 15,
    day: "15/11/2024",
    artilheiro: "Caio Barbosa",
    artilheiroFoto: sazuPhoto,
    mvp: "Thiago Goleleir",
    mvpFoto: sazuPhoto,
    pior: "Paulo",
    piorFoto: sazuPhoto,
    historicoTime1: ["VITORIA", "DERROTA", "EMPATE", "VITORIA", "DERROTA"],
    historicoTime2: ["VITORIA", "VITORIA", "DERROTA", "EMPATE", "VITORIA"],
  },
  {
    id: 12,
    time1: "AMARELO",
    time2: "PRETO",
    golsTime1: 10,
    golsTime2: 12,
    day: "22/11/2024",
    artilheiro: "Lucas Fernandes",
    artilheiroFoto: sazuPhoto,
    mvp: "Luan Fonseca",
    mvpFoto: sazuPhoto,
    pior: "Eduardo",
    piorFoto: sazuPhoto,
    historicoTime1: ["VITORIA", "DERROTA", "EMPATE", "VITORIA", "VITORIA"],
    historicoTime2: ["DERROTA", "VITORIA", "EMPATE", "DERROTA", "VITORIA"],
  },
  {
    id: 13,
    time1: "AMARELO",
    time2: "PRETO",
    golsTime1: 14,
    golsTime2: 8,
    day: "29/11/2024",
    artilheiro: "Rafael Pereira",
    artilheiroFoto: sazuPhoto,
    mvp: "Matheus Goulart",
    mvpFoto: sazuPhoto,
    pior: "João",
    piorFoto: sazuPhoto,
    historicoTime2: ["DERROTA", "VITORIA", "EMPATE", "DERROTA", "VITORIA"],
  },
];
