import React, { useContext, useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { FaQuoteLeft, FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { ThemeContext } from "../../contexts/ThemeContext";
import { referencesPhotos } from "../../data/testimonialsData";
import "./Testimonials.css";
import axios from "axios";
import boy1 from "../../assets/svg/testimonials/boy1.svg";

function Testimonials() {
  const { theme } = useContext(ThemeContext);
  const [references, setReferences] = useState([]);
  const sliderRef = useRef();
  const settings = {
    dots: true,
    adaptiveHeight: true,
    infinite: true,
    speed: 800,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    margin: 3,
    loop: true,
    autoplaySpeed: 5000,
    draggable: true,
    swipeToSlide: true,
    swipe: true,
  };

  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };

  const getFeedBacks = async () => {
    try {
      const res = await axios.get(
        `https://splashpg.com.br/api/feedback/getSazuFeedbacks`,
        {}
      );

      const filteredReferences = res.data.filter(
        (obj) => obj.id !== 44 && obj.id !== 59 && obj.id !== 58
      );

      setReferences(filteredReferences);
    } catch ({ response }) {
      console.error(response);
    }
  };

  useEffect(() => {
    getFeedBacks();
  }, []);

  const parseReferenceString = (referenceString) => {
    // Verifica se a string de entrada é válida
    if (typeof referenceString !== "string" || referenceString.trim() === "") {
      throw new Error("A string de entrada deve ser uma string não vazia.");
    }

    const reference = {};

    // Extrair o nome
    const nomeMatch = referenceString.match(/Nome:\s*(.*?)\s*--/);
    if (!nomeMatch || !nomeMatch[1].trim()) {
      throw new Error("O nome não foi encontrado ou está vazio.");
    }
    reference.nome = nomeMatch[1].trim();

    if (reference.nome === "Maisq") {
      reference.nome = "Maisa Iavorski";
    }

    // Extrair o cargo
    const cargoMatch = referenceString.match(/Cargo:\s*(.*?)\s*--/);
    if (!cargoMatch || !cargoMatch[1].trim()) {
      throw new Error("O cargo não foi encontrado ou está vazio.");
    }
    reference.cargo = cargoMatch[1].trim();

    if (reference.cargo === "Splash") {
      reference.cargo = "Empresa Splash";
    }

    // Extrair a mensagem
    const mensagemMatch = referenceString.match(/Mensagem:\s*(.*)/);
    if (!mensagemMatch || !mensagemMatch[1].trim()) {
      throw new Error("A mensagem não foi encontrada ou está vazia.");
    }
    reference.mensagem = mensagemMatch[1].trim();

    if (reference.mensagem.length > 400) {
      reference.mensagem = reference.mensagem.slice(0, 400) + "...";
    }

    return reference;
  };

  return (
    <>
      {references?.length > 0 && (
        <div
          className="testimonials"
          id="referencias"
          style={{ backgroundColor: theme.primary }}
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-offset="300"
          data-aos-delay="00"
        >
          <div className="testimonials--header">
            <h1 style={{ color: theme.secondary }}>Referências</h1>
            <h2 style={{ color: theme.secondary }}>
              Reuni feedbacks de pessoas que já tive o prazer compartilhar o
              mesmo ambiente profissional.
              <br /> Agradeço cada um que enviou uma mensagem. 🙏{" "}
            </h2>
          </div>
          <div className="testimonials--body">
            <FaQuoteLeft className="quote" style={{ color: theme.secondary }} />
            <div
              className="testimonials--slider"
              style={{ backgroundColor: theme.primary }}
            >
              <Slider {...settings} ref={sliderRef}>
                {references?.map((test) => (
                  <div className="single--testimony" key={test?.id}>
                    <div className="testimonials--container">
                      <div
                        className="review--img"
                        style={{
                          backgroundColor: theme.secondary,
                        }}
                      >
                        <img
                          src={
                            referencesPhotos[0]["idPessoa" + test?.id] ?? boy1
                          }
                          alt={parseReferenceString(test?.fdb_message).nome}
                        />
                      </div>
                      <div
                        className="review--content"
                        style={{
                          backgroundColor: theme.secondary,
                          color: theme.tertiary,
                        }}
                      >
                        <p>
                          {parseReferenceString(test?.fdb_message).mensagem}
                        </p>
                        <h1>{parseReferenceString(test?.fdb_message).nome}</h1>
                        <h4>{parseReferenceString(test?.fdb_message).cargo}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <button
                className="prevBtn"
                onClick={gotoPrev}
                style={{ backgroundColor: theme.secondary }}
              >
                <FaArrowLeft
                  style={{ color: theme.primary }}
                  aria-label="Previous testimonial"
                />
              </button>
              <button
                className="nextBtn"
                onClick={gotoNext}
                style={{ backgroundColor: theme.secondary }}
              >
                <FaArrowRight
                  style={{ color: theme.primary }}
                  aria-label="Next testimonial"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Testimonials;
