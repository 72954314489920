/* eslint-disable */
import { AiOutlineMail,  AiOutlineFundProjectionScreen } from "react-icons/ai";
import { MdOutlineWeb } from "react-icons/md";
import { GrDocumentConfig } from "react-icons/gr";
import { IoCloudDoneSharp } from "react-icons/io5";

export const servicesData = [
  {
    id: 10,
    title: "Web Site",
    icon: <MdOutlineWeb />,
  },
  {
    id: 20,
    title: "Projeto Completo",
    icon: <AiOutlineFundProjectionScreen />,
  },
  {
    id: 30,
    title: "Email Marketing",
    icon: <AiOutlineMail />,
  },
  {
    id: 40,
    title: "Hospedagem Cloud",
    icon: <IoCloudDoneSharp />,
  },
  {
    id: 50,
    title: "Manutenção de Sistema",
    icon: <GrDocumentConfig />,
  },
];

// Uncomment your required service.
// Couldn't find the required services? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new
// You can also add on your own 😉.
