export const educationData = [
  {
    id: 1,
    institution: "UEPG - Universidade Estadual de Ponta Grossa",
    course: "Engenharia de Computação",
    startYear: "2016",
    endYear: "2020",
  },
  {
    id: 2,
    institution: "Colégio Sant’ana",
    course: "Técnico em Informática",
    startYear: "2013",
    endYear: "2015",
  },
  {
    id: 3,
    institution: "Colégio Sant’ana",
    course: "Ensino Fundamental e Ensino Médio",
    startYear: "2011",
    endYear: "2015",
  },
];
