import {
  greenThemeLight,
  greenThemeDark,
  bwThemeLight,
  bwThemeDark,
  blueThemeLight,
  blueThemeDark,
  redThemeLight,
  redThemeDark,
  orangeThemeLight,
  orangeThemeDark,
  purpleThemeLight,
  purpleThemeDark,
  pinkThemeLight,
  pinkThemeDark,
  yellowThemeLight,
  yellowThemeDark,
} from "../theme/theme";

export const themes = {
  greenLight: greenThemeLight,
  greenDark: greenThemeDark,
  bwLight: bwThemeLight,
  bwDark: bwThemeDark,
  blueLight: blueThemeLight,
  blueDark: blueThemeDark,
  redLight: redThemeLight,
  redDark: redThemeDark,
  orangeLight: orangeThemeLight,
  orangeDark: orangeThemeDark,
  purpleLight: purpleThemeLight,
  purpleDark: purpleThemeDark,
  pinkLight: pinkThemeLight,
  pinkDark: pinkThemeDark,
  yellowLight: yellowThemeLight,
  yellowDark: yellowThemeDark,
};

// Define o tema inicial
export const themeData = {
  theme: themes,
};
