export const aboutData = {
  title: "Quem Sou Eu",
  description1:
    "Olá, meu nome é Matheus, tenho 26 anos e moro em Ponta Grossa no Paraná. Gosto muito de futebol, poker, churrasco e se reunir com amigos. ⚽️",
  description2: `Sou graduado em engenharia da computação pela UEPG e atualmente trabalho como 
                desenvolvedor Pleno Full Stack para a empresa KMM, utilizando Laravel, SQL, React JS, 
                Python, banco de dados com Oracle, PostGres e MySQL. 💻`,
  description3: `Em meu último cargo eu trabalhava com desenvolvimento web frontend para a empresa Lösung Tecnologia, 
                utilizando React, TypeScript, Tailwind e consumo de API's
                Além disso, já usei e tenho conhecimento em bootstrap, CSS, PHP, WordPress e JavaScript. 💻`,
  image: 2,
};
