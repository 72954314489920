import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { VscChromeClose } from "react-icons/vsc";
import {
  MdOutlineDoNotDisturbOn,
  MdOutlineCancel,
  MdOutlineCancelPresentation,
} from "react-icons/md";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { IoMdFootball } from "react-icons/io";
import { GiTrophy } from "react-icons/gi";
import { TfiFaceSad } from "react-icons/tfi";
import { Link } from "react-router-dom";
import "./PartidaCard.css";

function PartidaCard({
  id,
  tela,
  time1,
  time2,
  golsTime1,
  golsTime2,
  day,
  artilheiro,
  artilheiroFoto,
  artilheiroTime,
  mvp,
  mvpFoto,
  mvpTime,
  pior,
  piorFoto,
  piorTime,
  historicoTime1,
  historicoTime2,
  escalacaoTime1,
  escalacaoTime2,
}) {
  const { theme } = useContext(ThemeContext);

  const getIconeHistory = (history, index) => {
    switch (history) {
      case "DERROTA":
        return <MdOutlineCancel key={index} className="redGols" />;
      case "EMPATE":
        return (
          <MdOutlineDoNotDisturbOn
            key={index}
            style={{ color: theme.tertiary }}
          />
        );
      case "VITORIA":
        return <RiCheckboxCircleLine key={index} className="greenGols" />;
      default:
        return null;
    }
  };

  return (
    <Link
      key={id}
      to={`/futsal-cpj/partida/${id}`}
      className={`card-partida-main tela-${tela}`}
    >
      <div key={id} className={`card ${theme.type} ${tela}`}>
        <div style={{ color: theme.primary }} className="times">
          <div className="time1">
            <h2>{time1}</h2>
            <h2
              className={`${
                golsTime1 > golsTime2
                  ? "greenGols"
                  : golsTime1 < golsTime2
                  ? "redGols"
                  : ""
              }`}
            >
              {golsTime1}
            </h2>
          </div>
          <div className="vs">
            <VscChromeClose />
          </div>
          <div className="time2">
            <h2>{time2}</h2>
            <h2
              className={`${
                golsTime1 > golsTime2
                  ? "redGols"
                  : golsTime1 < golsTime2
                  ? "greenGols"
                  : ""
              }`}
            >
              {golsTime2}
            </h2>
          </div>
        </div>
        <h3 style={{ color: theme.primary }}>{day}</h3>
        {escalacaoTime1 && (
          <div className="players">
            <div className="players-time1">
              {escalacaoTime1?.map((player) => (
                <p key={player.id} style={{ color: theme.tertiary }}>
                  <img src={player.jogadorFoto} alt="player" />{" "}
                  {player.jogadorNome}
                  {Array(player.gols)
                    .fill()
                    .map((_, index) => (
                      <IoMdFootball key={index} />
                    ))}
                  {Array(player.faltas)
                    .fill()
                    .map((_, index) => (
                      <MdOutlineCancelPresentation key={index} />
                    ))}{" "}
                </p>
              ))}
            </div>
            <div className="players-time2">
              {escalacaoTime2?.map((player) => (
                <p key={player.id} style={{ color: theme.tertiary }}>
                  <img src={player.jogadorFoto} alt="player" />{" "}
                  {player.jogadorNome}
                  {Array(player.gols)
                    .fill()
                    .map((_, index) => (
                      <IoMdFootball key={index} />
                    ))}
                  {Array(player.faltas)
                    .fill()
                    .map((_, index) => (
                      <MdOutlineCancelPresentation key={index} />
                    ))}
                </p>
              ))}
            </div>
          </div>
        )}
        <h4
          style={{ color: theme.tertiary }}
          className={`lado-${artilheiroTime}`}
        >
          Artilheiro <IoMdFootball /> <img src={artilheiroFoto} alt="player" />{" "}
          {artilheiro} (7x)
        </h4>
        <h6 style={{ color: theme.tertiary }} className={`lado-${mvpTime}`}>
          Melhor do Jogo <GiTrophy /> <img src={mvpFoto} alt="player" /> {mvp}{" "}
          (4x)
        </h6>
        <h5 style={{ color: theme.tertiary }} className={`lado-${piorTime}`}>
          Pior do Jogo <TfiFaceSad /> <img src={piorFoto} alt="player" /> {pior}{" "}
          (2x)
        </h5>
        <div className="history">
          <div className="history-esquerda">
            {historicoTime1?.map((resultado, index) =>
              getIconeHistory(resultado, index)
            )}
          </div>
          <div className="history-direita">
            {historicoTime2?.map((resultado, index) => getIconeHistory(resultado, index))}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default PartidaCard;
